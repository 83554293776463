<template>
  <form>
    <!--begin::Header-->
    <div
        class="d-flex align-items-center py-10 px-8 bgi-size-cover bgi-no-repeat rounded-top"
        :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <span class="btn btn-md btn-icon bg-white-o-15 mr-4">
        <i class="flaticon2-shopping-cart-1 text-success"/>
      </span>
      <h4 class="text-white m-0 flex-grow-1 mr-3">购物车</h4>
      <button type="button" class="btn btn-success btn-sm">{{ preOrders.length }} 个商品</button>
    </div>
    <!--end::Header-->

    <div class="scroll scroll-push">
      <perfect-scrollbar
          class="scroll"
          style="max-height: 30vh; position: relative;"
      >
        <!--begin::Item-->
        <template v-for="(item, i) in preOrders">
          <div class="d-flex align-items-center justify-content-between p-8" v-bind:key="i">
            <div class="d-flex flex-column mr-2">
              <a href="#" class="font-weight-bold text-dark-75 font-size-lg text-hover-primary">
                {{ item.brandName }} / {{ item.seriesName }} {{ item.productName }}
              </a>
              <span class="text-muted">
                <span class="mr-1">{{ item.material }}</span>
                <span class="mr-1">{{ item.color }}</span>
                <span>{{ item.specs }}</span>
              </span>
              <div class="d-flex align-items-center mt-2">
                <span class="font-weight-bold mr-1 text-dark-75 font-size-3">
                 ¥ {{ item.currentPrice }}
                </span>
                <span class="text-muted mr-1">for</span>
                <span class="font-weight-bold mr-2 text-dark-75 font-size-3">
                  {{ item.productQuantity }}
                </span>
                <button class="btn btn-xs btn-light-success btn-icon mr-2"
                        :disabled="item.productQuantity<=1" v-on:click="--item.productQuantity">
                  <i class="ki ki-minus icon-xs"></i>
                </button>
                <button class="btn btn-xs btn-light-success btn-icon mr-2" v-on:click="++item.productQuantity">
                  <i class="ki ki-plus icon-xs"></i>
                </button>
                <button class="btn btn-xs btn-light-danger   btn-icon" v-on:click="_delete(item.skuId)">
                  <i class="la la-trash icon-lg"></i>
                </button>
              </div>
            </div>
            <a href="#" class="symbol symbol-70 flex-shrink-0">
              <img :src="item.productImage" alt=""/>
            </a>
          </div>
        </template>
        <!--end::Item-->

        <div class="separator separator-solid"></div>
      </perfect-scrollbar>
    </div>

    <!--begin::Summary-->
    <div class="p-8">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <span class="font-weight-bold text-muted font-size-sm mr-2">商品数量</span>
        <span class="font-weight-bolder text-dark-50 text-right">{{ statistic.count || 0 }}</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-7">
        <span class="font-weight-bold text-muted font-size-sm mr-2">总金额</span>
        <span class="font-weight-bolder text-danger text-right">¥ {{ statistic.amount || 0 }}</span>
      </div>
      <div class="text-right">
        <button type="button" class="btn btn-danger text-weight-bold" v-on:click="onPreOrder">
          立即下单
        </button>
      </div>
    </div>
    <!--end::Summary-->
  </form>
</template>

<script>
import {mapGetters} from "vuex";
import ApiService from "@/core/services/api.service";

export default {
  name: "ShoppingCarts",
  data() {
    return {
      preOrders: []
    };
  },
  methods: {
    getPreOrder(shoppingCarts) {
      ApiService.post("/api/order/preview", shoppingCarts).then(({data}) => {
        if (data.success) {
          this.preOrders = data.result;
        }
      })
    },

    onPreOrder() {
      this.$parent.hide();
      this.$store.dispatch("set_preskus", this.shoppingCarts).then(() => {
        this.$router.push({
          path: "/client-side/order/preview",
          query: {
            iscart: true
          }
        });
      })
    },
    _delete(skuId) {
      this.$store.dispatch("delete_cart", skuId).then(() => {
      })
    }
  },
  created() {
    this.$store.dispatch("init_cart");
  },
  computed: {
    ...mapGetters(["shoppingCarts"]),
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    },
    statistic() {
      let statistic = {
        count: 0,
        amount: 0
      }
      if (this.preOrders != undefined && this.preOrders.length > 0) {
        this.preOrders.forEach(function (item) {
          statistic.count = parseInt(item.productQuantity) + statistic.count;
          statistic.amount = parseFloat(item.actualAmount) + statistic.amount;
        })
      }
      return statistic;
    }
  },
  watch: {
    shoppingCarts: {
      handler(newValue, oldValue) {
        this.getPreOrder(newValue)
      },
      deep: true,
    }
  }
};
</script>
