<template>
  <!-- begin:: Header -->
  <div id="kt_header" ref="kt_header" class="header" v-bind:class="headerClasses">
    <div class="container-fluid d-flex align-items-center justify-content-between">
      <!-- begin:: Header Menu -->
      <div class="header-menu-wrapper header-menu-wrapper-left" ref="kt_header_menu_wrapper">
        <router-link to="/home" href="#" v-if="showToolBar">
          <div class="d-flex align-items-center flex-lg-fill">
            <span class="mr-2">
              <i class="fab fa-amazon-pay mr-1 font-weight-bolder display-4"></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder">
                <font size="4">Mgoogo Pay</font>
              </span>
            </div>
          </div>

        </router-link>

        <!--        <div v-if="headerMenuEnabled" id="kt_header_menu" ref="kt_header_menu"-->
        <!--             class="header-menu header-menu-mobile"-->
        <!--             v-bind:class="headerMenuClasses">-->
        <!--          &lt;!&ndash; example static menu here &ndash;&gt;-->
        <!--          <KTMenu></KTMenu>-->
        <!--        </div>-->

      </div>

      <!-- end:: Header Menu -->
      <!-- begin:: Header Topbar -->
      <div class="topbar">

        <!--begin: 搜索 -->
        <!--        <b-dropdown-->
        <!--            size="sm"-->
        <!--            id="kt_quick_search_toggle"-->
        <!--            variant="link"-->
        <!--            toggle-class="topbar-item text-decoration-none"-->
        <!--            no-caret right no-flip v-if="showToolBar">-->
        <!--          <template v-slot:button-content>-->
        <!--            <div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">-->
        <!--          <span class="svg-icon svg-icon-xl svg-icon-primary">-->
        <!--            <inline-svg src="media/svg/icons/General/Search.svg"/>-->
        <!--          </span>-->
        <!--            </div>-->
        <!--          </template>-->
        <!--          <b-dropdown-text tag="div" class="min-w-md-350px">-->
        <!--            <KTSearchDefault></KTSearchDefault>-->
        <!--          </b-dropdown-text>-->
        <!--        </b-dropdown>-->
        <!--end: 搜索 -->

        <!--begin: API 文档 -->
        <div v-if="showToolBar" class="btn   btn-clean btn-dropdown btn-lg"
          v-b-tooltip.hover title="API 文档">
          <router-link :to="{path:'/home/doc'}" target="_blank" tag="a" rel="opener">
            <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
              <span class="symbol symbol-35 symbol-light-success">
                <span class="svg-icon svg-icon-xl svg-icon-primary">
                  <inline-svg src=" media/svg/icons/Communication/Chat6.svg" />
                </span>
              </span>
              <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline  ml-3">
                API 文档
              </span>
            </div>

          </router-link>
        </div>
        <!--end: API 文档 -->

        <!--begin: 管理后台 -->
        <div v-if="showToolBar&&isAuthenticated&&currentUser.userType==1" class="btn  btn-clean btn-dropdown btn-lg  "
          v-b-tooltip.hover title="管理后台">
          <router-link to="/profile" href="#">
            <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg  ">
              <span class="symbol symbol-35 symbol-light-success">
                <span class="svg-icon svg-icon-xl svg-icon-primary">
                  <inline-svg src=" media/svg/icons/Devices/Display1.svg" />
                </span>
              </span>
              <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline ml-3 ">
                管理后台
              </span>
            </div>

          </router-link>
        </div>
        <!--end: 管理后台 -->


        <!--begin: 个人中心-->
        <b-dropdown v-if="isAuthenticated" ref="dp_profile" size="sm" variant="link"
          toggle-class="topbar-item text-decoration-none" no-caret right no-flip>
          <template v-slot:button-content>
            <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
              <span class="symbol symbol-35 symbol-light-success">
                <span class="svg-icon svg-icon-xl svg-icon-primary">
                  <inline-svg src="media/svg/icons/General/User.svg" />
                </span>
              </span>
              <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3 ml-1">
                {{ currentUser.userName }}
              </span>
            </div>
          </template>
          <b-dropdown-text tag="div" class="min-w-md-350px">
            <div>
              <!--begin::Header -->
              <div class="d-flex flex-column flex-center py-10 bgi-size-cover bgi-no-repeat rounded-top"
                :style="{ backgroundImage: `url(${backgroundImage})` }">
                <h4 class="text-white font-weight-bold">
                  用户中心
                </h4>
              </div>
              <!--end::Header -->

              <!--begin::Nav -->
              <div class="row row-paddingless">
                <!--begin:Item-->
                <template v-for="(item, i) in menus">
                  <div class="col-6 " v-bind:key="i" v-on:click="onSelectMenu(item)">
                    <div class="d-block py-10 px-5 text-center bg-hover-light border-right border-bottom">
                      <span class="svg-icon svg-icon-3x svg-icon-success">
                        <!--begin::Svg Icon-->
                        <inline-svg :src="item.svg" />
                        <!--end::Svg Icon-->
                      </span>
                      <span class="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">
                        {{ item.title }}
                      </span>
                      <span class="d-block text-dark-50 font-size-lg">
                        {{ item.desc }}
                      </span>
                    </div>
                  </div>
                </template>
                <!--end:Item-->
              </div>
              <!--end::Nav -->
            </div>
          </b-dropdown-text>
        </b-dropdown>
        <!--end: 个人中心 -->

        <!--begin: 登录注册 -->
        <div v-if="!isAuthenticated" class="btn  btn-clean btn-dropdown btn-lg  ">
          <router-link to="/login" href="#">
           <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
              <span class="symbol symbol-35 symbol-light-success">
                     <span class="svg-icon svg-icon-xl svg-icon-primary">
              <inline-svg src=" media/svg/icons/Navigation/Sign-in.svg" />
            </span>
              </span>
              <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline  ml-3">
                 登录入口
              </span>
           </div>
          </router-link>
        </div>
        <!--end: 登录注册 -->
      </div>
      <!-- end:: Header Topbar -->
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
  import {
    mapGetters
  } from "vuex";
  import KTLayoutHeader from "@/assets/js/layout/base/header.js";
  import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
  import KTSearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
  import ShoppingCart from "@/view/layout/extras/dropdown/ShoppingCart.vue";
  import i18nService from "@/core/services/i18n.service.js";
  import {
    LOGOUT
  } from "@/core/services/store/auth.module";

  export default {
    name: "KTHeader",
    components: {
      KTSearchDefault,
      ShoppingCart,
    },
    data() {
      return {
        languageFlag: "",
        languages: i18nService.languages,
        menus: [{
            title: "个人中心",
            desc: "profile",
            svg: process.env.BASE_URL + "media/svg/icons/General/User.svg",
            path: "/profile?index=account",
            isRouter: true
          },
          {
            title: "密码修改",
            desc: "password",
            svg: process.env.BASE_URL + "media/svg/icons/Code/Lock-overturning.svg",
            path: "/profile?index=password",
            isRouter: true
          },
          {
            title: "我的订单",
            desc: "orders",
            svg: process.env.BASE_URL + "media/svg/icons/Shopping/Box2.svg",
            path: "/merch/order/list",
            isRouter: true
          },
          {
            title: "退出登录",
            desc: "logout",
            svg: process.env.BASE_URL + "media/svg/icons/Communication/Reply-all.svg",
            path: "/logout.do",
            isRouter: false
          }
        ]
      };
    },
    mounted() {
      // Init Desktop & Mobile Headers
      KTLayoutHeader.init("kt_header", "kt_header_mobile");

      // Init Header Menu
      KTLayoutHeaderMenu.init(
        this.$refs["kt_header_menu"],
        this.$refs["kt_header_menu_wrapper"]
      );
    },
    methods: {
      onSelectMenu(menu) {
        this.$refs['dp_profile'].hide();
        if (menu.isRouter) {
          let path = menu.path;
          // if (this.showToolBar) {
          //   path = "/" + path;
          // }

          this.$router.push({
            path: path
          });
        } else {
          this.$store.dispatch(LOGOUT)
            .then(() => this.$router.push({
              name: "login"
            })).catch(({
              err
            }) => {
              console.error(err)
            });
        }
      },
    },
    computed: {
      ...mapGetters(["layoutConfig", "getClasses", "currentUser", "isAuthenticated"]),
      backgroundImage() {
        return process.env.BASE_URL + "media/misc/bg-1.jpg";
      },
      showToolBar() {
        return this.$route.path.indexOf("home") > -1;
      },

      /**
       * Check if the header menu is enabled
       * @returns {boolean}

       headerMenuEnabled() {
        return !!this.layoutConfig("header.menu.self.display");
      },*/

      /**
       * Get extra classes for header based on the options
       * @returns {null|*}
       */
      headerClasses() {
        const classes = this.getClasses("header");
        if (typeof classes !== "undefined") {
          return classes.join(" ");
        }
        return null;
      },

      /**
       * Get extra classes for header menu based on the options
       * @returns {null|*}

       headerMenuClasses() {
        const classes = this.getClasses("header_menu");
        if (typeof classes !== "undefined") {
          return classes.join(" ");
        }
        return null;
      },
       */

    }
  };
</script>

<style lang="scss">
  .topbar {
    .dropdown-toggle {
      padding: 0;

      &:hover {
        text-decoration: none;
      }

      &.dropdown-toggle-no-caret {
        &:after {
          content: none;
        }
      }
    }

    .dropdown-menu {
      margin: 0;
      padding: 0;
      outline: none;

      .b-dropdown-text {
        padding: 0;
      }
    }
  }
</style>