<template>
  <div>
    <!-- begin::Sticky Toolbar -->
    <ul class="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
      <li id="qq" class="nav-item mb-2">
        <a class="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary">
          <i class="fab la-qq text-danger icon-xl"></i>
        </a>

        <b-tooltip target="qq" triggers="hover" placement="left">
          <div v-for="(item,index) in getContact.qq" class="text-hover-warning mb-1 pl-4 pr-4 pt-1 pb-1 font-size-lg">
            <a :href="'http://wpa.qq.com/msgrd?v=3&amp;uin='+item.qq+'&amp;site=qq&amp;menu=yes'" target="_blank">
              <i class="fab la-qq text-primary"></i> 客服 {{ index + 1 }}
            </a>
          </div>
        </b-tooltip>
      </li>

      <li id="wechat" class="nav-item">
        <a class="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning">
          <i class="la la-wechat text-danger   icon-2x"></i>
        </a>
        <b-tooltip target="wechat" triggers="hover" placement="left" style="height: 160px">
          客服微信
          <div v-for="(item,index) in getContact.wechat" class="mt-2 pa-3">
            <b-img left :src="item.qrcode" alt="客服微信" width="150" height="150"></b-img>
          </div>
        </b-tooltip>
      </li>
    </ul>
    <!-- end::Sticky Toolbar -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "KTStickyToolbar",
  computed: {
    ...mapGetters(["getContact"]),
  },
};
</script>
